// theme.js
import { extendTheme } from "@chakra-ui/react"

// Global style overrides
import styles from "./foundations/styles"
import colors from "./foundations/colors"
import holidays from "./foundations/holidays"
import state from "./foundations/state"
import components from "./components"

// 2. Extend the theme to include custom colors, fonts, etc
const theme = extendTheme(
    {
        ...styles,
        ...colors,
        ...components,
        ...holidays,
        ...state,
    } );


export default theme;
