import {
    Box,
    Center,
    Text, useColorMode
} from "@chakra-ui/react";

import { FaEtsy, FaFacebook, FaTwitter } from "react-icons/fa";

import {AutoGrid} from "../autoGrid";

const Footer = () => {
    const { colorMode } = useColorMode();
    const bgColor = { light : 'gray.300', dark : 'gray.600' };
    return (
        <AutoGrid minColWidth="xs" bg={ bgColor[ colorMode ] }>
            <Box
                key={1}
                flex="1"
                h="50px"
            >
                <Center mt={3}><Text fontSize={"sm"}>Copyright © 2020 - RJPDesigns</Text></Center>

            </Box>
            <Box
                key={2}
                flex="1"
                h="50px"
            />
            <Box
                key={3}
                flex="1"
                h="50px"
            >
                <Center mt={4}>
                    <FaTwitter style={{marginRight: "20px"}} />
                    <FaFacebook style={{marginRight: "20px"}} />
                    <FaEtsy />
                </Center>
            </Box>
        </AutoGrid>
    );
}

export default Footer;
