let holidays = {
    holidays : {
        text : {
            none : "Welcome to the site!",
            stPatricksDay : "Happy St.Patty's Day!",
            valentinesDay : "Happy Valentines Day!",
            halloween : "Good luck trick-or-treating!",
            christmas : "Merry Christmas!"
        },
        colors : {
            none : {
                "one" : "#808080",
                "two" : "#808080",
                "three" : "#808080"
            },
            stPatricksDay : {
                "one" : "#224D17",
                "two" : "#60A830",
                "three" : "#099441"
            },
            valentinesDay : {
                "one" : "#b11d4d",
                "two" : "#fd6fa0",
                "three" : "#e986a3"
            },
            halloween : {
                "one" : "#810806",
                "two" : "#BF200E",
                "three" : "#FA4113"
            },
            christmas : {
                "one" : "#44b09e",
                "two" : "#e0d2c7",
                "three" : "#e01c34"
            },
        }
    }
}

export default holidays;
