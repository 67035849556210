import { Box, Flex } from '@chakra-ui/react';

const BGImage = (props) => {
    return (
        <Box
            as={Flex}
            w="100vw"
            h="100vh"
            bgImage={`url(${props.image})`}
            bgPosition="center"
            bgSize="cover"
            bgRepeat="no-repeat"
            flexDirection="column"
            align="center"
            justify="center"
        >
            {props.children}
        </Box>
    );
}

export default BGImage;
