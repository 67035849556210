import { theme } from '@chakra-ui/react';

let colors =
    {
        colors : {
            brandSecondary : {
                50 : "#ffeee5",
                100 : "#ffcbb3",
                200 : "#ffa380",
                300 : "#ef815c",
                400 : "#e25b36",
                500 : "#c9421d",
                600 : "#a32b10",
                700 : "#801300",
                800 : "#4d0900",
                900 : "#1a0200"
            },
            brand : {
                50 : "#eae5ff",
                100 : "#bfb3ff",
                200 : "#9485f9",
                300 : "#7266e6",
                400 : "#4b42d6",
                500 : "#3229bc",
                600 : "#1f1999",
                700 : "#0a067a",
                800 : "#02004d",
                900 : "#00001a"
            },
            success : theme.colors.green,
            error : theme.colors.red,
            warning : theme.colors.yellow,
        }
    }

export default colors;
