import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import ModalImage from "../react-modal-image";

import example1 from '../../assets/example1.jpg';
import example2 from '../../assets/example2.jpg';
import example3 from '../../assets/example3.jpg';
import example4 from '../../assets/example4.jpg';

export const photos = [
    {
        src : example1,
        width : 4,
        height : 3
    },
    {
        src : example2,
        width : 100,
        height : 100
    },
    {
        src : example3,
        width : 1,
        height : 1
    },
    {
        src : example4,
        width : 1,
        height : 1
    }
];

const Portfolio = () => {
    return (
        <>
            <VStack w={ "100vw" }>
                <Box>
                    <Text fontSize={ "3xl" }>My Portfolio</Text>
                </Box>
                <Box w={ "75%" }>
                    <Text fontSize={ "xl" }>Here are a few examples of some pieces I have completed. They show the
                        breadth of the materials I have worked on, the commissions I have done and how final pieces I
                        produce look.</Text>
                </Box>
                <HStack spacing="24px">
                    <Box>
                        <ModalImage
                            small={ example1 }
                            medium={ example1 }
                            large={ example1 }
                            alt="Hello World!"
                            hideDownload={ true }
                            style={{cursor: "pointer", height: "200px"}}
                        />
                    </Box>
                    <div style={ { maxWidth : "300px" } }>
                        <ModalImage
                            small={ example2 }
                            medium={ example2 }
                            large={ example2 }
                            alt="Hello World 2!"
                            hideDownload={ true }
                            style={{cursor: "pointer", height: "200px"}}
                        />
                    </div>
                    <div style={ { maxWidth : "300px" } }>
                        <ModalImage
                            small={ example3 }
                            medium={ example3 }
                            large={ example3 }
                            alt="Hello World 3!"
                            hideDownload={ true }
                            style={{cursor: "pointer", height: "200px"}}
                        />
                    </div>
                    <div style={ { maxWidth : "300px" } }>
                        <ModalImage
                            small={ example4 }
                            medium={ example4 }
                            large={ example4 }
                            alt="Hello World 4!"
                            hideDownload={ true }
                            style={{cursor: "pointer", height: "200px"}}
                        />
                    </div>
                </HStack>
            </VStack>



        </>
    )
        ;
}

export default Portfolio;
