import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {HelmetProvider} from 'react-helmet-async';
import {ChakraProvider, CSSReset,} from "@chakra-ui/react";
import {RecoilRoot} from 'recoil';
import theme from './theme';
import {ErrorBoundary} from './components/errors';

ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <CSSReset/>
            <RecoilRoot>
                <HelmetProvider>
                    <ErrorBoundary>
                        <App/>
                    </ErrorBoundary>
                </HelmetProvider>
            </RecoilRoot>
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
