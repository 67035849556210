import { mode } from "@chakra-ui/theme-tools";

let index = {
    Button : {
        sizes : {
            xl : {
                h : "56px",
                fontSize : "22px",
                px : "32px",
            },
            xxl : {
                h : "66px",
                fontSize : "30px",
                px : "42px",
            },
        },
        variants : {
            "with-shadow" : (props) => ({
                boxShadow : "0 0 0 8px #efdfde",
                border: `4px solid ${props.colorScheme}.500`,
                bg: mode(
                    `${props.colorScheme}.200`,
                    `${props.colorScheme}.800`
                )(props),
                _hover: {
                    bg: mode(`${props.colorScheme}.800`, "black")(props)
                }
            }),
            "scaleup" : {
                scale : 1.1,
                bg: "green.500",
                color: "white",
            },
            "scaledown" : {
                hover: {
                    scale : 0.9,
                    bg: "green.500",
                    color: "white",
                }
            },
        },
    }
}

export default index;
