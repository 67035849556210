import { useState } from 'react';
import {
    Flex,
    useColorMode,
    IconButton,
    Box,
    Image,
    Spacer,
    Text,
    Link, useColorModeValue, useTheme
} from '@chakra-ui/react';
import { FaMoon, FaSun } from "react-icons/fa"

const MenuItems = ( { children } ) => (
    <Text mt={ { base : 4, md : 0 } } mr={ 6 } display="block">
        { children }
    </Text>
);

const Navbar = () => {
    const theme = useTheme();
    const { colorMode, toggleColorMode } = useColorMode();
    const bgColor = { light : 'gray.300', dark : 'gray.600' };
    const textColor = { light : 'black', dark : 'gray.100' };
    const SwitchIcon = useColorModeValue( FaMoon, FaSun )
    const [ show, setShow ] = useState( false );
    const handleToggle = () => setShow( !show );

    return (
        <Flex
            w='100vw'
            bg={ bgColor[ colorMode ] }
            color={ textColor[ colorMode ] }
            justify='center'
            align='center'
            fontSize={ [ 'md', 'lg', 'xl', 'xl' ] }
            h='7vh'
            boxShadow='md'
            p={ 2 }
        >
            <Flex w={ '100vw' } justify='space-around' align={ "center" }
                  bg={ bgColor[ colorMode ] }>
                <Box>
                    <Image h='6vh' src={ theme.state.theme.logo } alt='Logo of Violet Art & Design'/>
                </Box>
                <Spacer/>
                <Box
                    display={ { sm : show ? "block" : "none", md : "flex" } }
                    width={ { sm : "full", md : "auto" } }
                    alignItems="center"
                    flexGrow={ 1 }
                    color={ textColor[ colorMode ] }
                >
                    <MenuItems>
                        <Link href='#home'>Home</Link>
                    </MenuItems>
                    <MenuItems>
                        <Link href='#about'>About</Link>
                    </MenuItems>
                    <MenuItems>
                        <Link href='#services'>Services</Link>
                    </MenuItems>
                    <MenuItems>
                        <Link href='#portfolio'>Portfolio</Link>
                    </MenuItems>
                    <MenuItems>
                        <Link href='#contact'>Contact</Link>
                    </MenuItems>
                </Box>
                <Spacer/>
                <Box display={ { base : "block", md : "none" } } mr={ 4 } onClick={ handleToggle }>
                    <svg
                        fill="white"
                        width="18px"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                    </svg>
                </Box>
                <Box display={ { base : "none", md : "block" } }>
                    <IconButton
                        rounded='full'
                        onClick={ toggleColorMode }
                        icon={ <SwitchIcon/> }>
                        Change Color Mode
                    </IconButton>
                </Box>
            </Flex>
        </Flex>
    );
};

export default Navbar;
