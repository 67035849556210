let styles = {
    styles : {
        global : {
            // styles for the `body`
            body : {
                bg : "gray.50",
                color : "white",
                WebkitTapHighlightColor: 'transparent',
                margin : 0,
            }
        }
    },
}

export default styles;
