let state = {
    state : {
        theme : {
            logo : "./assets/logo.svg",
            background: "https://unsplash.com/photos/V4EOZj7g1gw/download?force=true&w=1920",
            showSocialLinks : true,
        }
    },
}

export default state;
