import {
    Box,
    Button,
    Center,
    Divider,
    Heading,
    HStack,
    Image,
    Link,
    SimpleGrid,
    Text,
    //useColorMode,
    useTheme,
    VStack,
    Wrap,
    WrapItem
} from "@chakra-ui/react";

import SEO from './components/seo/index';
import Navbar from './components/navbar';
import BGImage from './components/bgImage';
import Footer from './components/footer';
import Portfolio from './components/portfolio';

import TextLoop from "react-text-loop";
import {IoHeartSharp} from "react-icons/io5";
import { BiCustomize, BiDice4, BiEnvelope } from "react-icons/bi";
import { IoIosPerson } from "react-icons/io"


function App() {
    //const {colorMode} = useColorMode();
    const theme = useTheme();

    return (
        <div id="home">
            <SEO title={"Home"}/>
            <Navbar/>
            <BGImage image={theme.state.theme.background}>
                <Heading fontSize={{base: "xl", md: "3xl", lg: "5xl"}} mt={"-100"}>Everything inspired by {" "}
                    <TextLoop>
                        <span>desire.{" "}</span>
                        <span>need.{"  "}</span>
                        <span>passion.</span>
                    </TextLoop>
                </Heading>
                <Wrap ml={{base: "-90", md: "-100", lg: "-180"}} mt={8}>
                    <WrapItem>
                        <Center w={{base: "200px", md: "320px", lg: "420px"}} h="100px">
                            <Text mt="5" color="brand.200" fontSize={{base: "md", md: "lg", lg: "xl"}}>
                                Get your creative ideas brought to life or browse my creations and get them delivered to your doorstep ready for that event, that gap on the wall or for someone special.
                            </Text>
                        </Center>
                    </WrapItem>
                </Wrap>
            </BGImage>


            <section id="about">
                <Center w={"100vw"} h="600px" backgroundColor={"brand.300"}>
                    <Box w={"100%"}>
                        <VStack
                            spacing={4}
                        >
                            <Box>
                                <Image
                                    boxSize="160px"
                                    src={theme.state.theme.logo}
                                    alt="Segun Adebayo"
                                />
                            </Box>
                            <Box>
                                <Text fontSize={"3xl"}>Violet Art & Design</Text>
                            </Box>
                            <Box w={"100px"}>
                                <Divider/>
                            </Box>
                            <Box>
                                <Text fontSize={"xl"}>Who am I and why am I here?</Text>
                            </Box>
                            <Box px={24}>
                                <Text fontSize={"lg"}>For a long time I have been making my own arts and crafts, my own
                                    pictures, decorations and many other items. More recently I started to give them as
                                    gifts to people, mainly for weddings, birthdays, christmas, etc.</Text>
                            </Box>
                            <Box px={24}>
                                <Text fontSize={"md"}>It has come time to open my private collection, my experience and
                                    expertise to anyone who would be interested in a piece of original, creative, custom
                                    made work.</Text>
                            </Box>
                        </VStack>
                        <Center>
                        </Center>
                    </Box>
                </Center>
            </section>

            <section id="services">
                <Center w={"100vw"} h="400px" backgroundColor={"white"}>
                    <Box w={"100%"}>
                        <SimpleGrid minChildWidth="120px" spacing="40px">
                            <Box height="260px">
                                <Center color={"brand.300"}>
                                    <BiCustomize size={"120px"}/>
                                </Center>
                                <Center>
                                    <Text as="b" fontSize={"xl"} color={"black"}>Everything unique</Text>
                                </Center>
                                <Center p={4}>
                                    <Text fontSize={"sm"} color={"black"}>Everyone is unique and so should their gifts, art work, candles, glasses etc.</Text>
                                </Center>
                            </Box>
                            <Box height="260px">
                                <Center color={"brand.300"}>
                                    <IoIosPerson size={"120px"}/>
                                </Center>
                                <Center>
                                    <Text as="b" fontSize={"xl"} color={"black"}>Everything made by me</Text>
                                </Center>
                                <Center p={4}>
                                    <Text fontSize={"sm"} color={"black"}>Everything is created by me and by hand.  I take great care to create something I would love, so I know you will too!</Text>
                                </Center>
                            </Box>
                            <Box height="260px">
                                <Center color={"brand.300"}>
                                    <BiDice4 size={"120px"}/>
                                </Center>
                                <Center>
                                    <Text as="b" fontSize={"xl"} color={"black"}>Everything & Anything</Text>
                                </Center>
                                <Center p={4}>
                                    <Text fontSize={"sm"} color={"black"}>Everything I have turned my hand to so far, from glass wall painting to painting on glasses, from wood etching to Christmas decorations.  I will work with anything.</Text>
                                </Center>
                            </Box>
                            <Box height="260px">
                                <Center color={"brand.300"}>
                                    <IoHeartSharp size={"120px"}/>
                                </Center>
                                <Center>
                                    <Text as="b" fontSize={"xl"} color={"black"}>Everything with Love</Text>
                                </Center>
                                <Center p={4}>
                                    <Text fontSize={"sm"} color={"black"}>Everything is designed, agreed and produced
                                        with the same love and attention to detail, I have come to expect.</Text>
                                </Center>
                            </Box>
                        </SimpleGrid>
                    </Box>
                </Center>
            </section>

            <section className="page-section" id="portfolio">
                <Center w={"100vw"} h="700px" backgroundColor={"brand.300"}>
                    <Portfolio />
                </Center>
            </section>

            <section className="page-section" id="contact">
                <Center w={"100vw"} h="400px" backgroundColor={"white"}>
                    <Box w={"100%"}>
                        <VStack
                            spacing={4}
                        >
                            <Box>
                                <Text fontSize={"3xl"} color={"brand.500"}>Let's Get In Touch!</Text>
                            </Box>
                            <Box w={"100px"} color={"brand.500"}>
                                <Divider/>
                            </Box>
                            <Box color={"black"}>
                                <Text>Ready to start your next project with us? Give us a call or send us an email and
                                    we will get back to you as soon as possible!</Text>
                            </Box>
                            <Box w={"100%"}>
                                <HStack spacing="24px" w={"100%"}>
                                    <Box w="50%" h="40px">
                                        <Center>
                                            <Text as={"b"} color={"black"} fontSize={"2xl"}>Contact Form</Text>
                                        </Center>
                                        <Center color={"brand.300"} mt={3}>
                                            <Button size={"xl"} leftIcon={<BiEnvelope />} colorScheme="brand" variant="solid">
                                                Email
                                            </Button>
                                        </Center>
                                    </Box>
                                    <Box w="50%" h="40px">
                                        <Center>
                                            <Text as={"b"} color={"black"} fontSize={"2xl"}>Email</Text>
                                        </Center>
                                        <Center color={"brand.300"}>
                                            <BiEnvelope size={"80px"} />
                                        </Center>
                                        <Center>
                                            <Text color={"black"}>
                                                <Link href={"mailto:contact@violetartdesign.com"}>contact@violetartdesign.com</Link>
                                            </Text>
                                        </Center>
                                    </Box>
                                </HStack>
                            </Box>
                        </VStack>
                    </Box>
                </Center>
            </section>

            <Footer />

        </div>
    );
}

export default App;
